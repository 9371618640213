import dataLayer from '@rover/globals/dataLayer';
import $ from '@rover/globals/jquery';
import Mustache from '@rover/globals/Mustache';
import Rover from '@rover/globals/Rover';
import _ from '@rover/globals/underscore';
import { captureError } from '@rover/rsdk/src/modules/ErrorReporting';
import Alerts from '@rover/utilities/Alerts';
import inject from '@rover/utilities/injector';
import { localStorage } from '@rover/utilities/storage';
import i18n from '@rover/utilities/translation';

$(() => {
  if (!$('.js-registration').length) {
    return;
  }

  // Impact attribution
  // in a slight timeout in case this is the frist page the user visits.
  // (a script in GTM captures the click id, so let's give it a second or two).
  const countryCodeSelector = '#country_code';
  window.setTimeout(() => {
    let clickId = null;
    if (localStorage) {
      clickId = localStorage.getItem('clickId');
    }

    if (clickId) {
      const attributionInputs = `
            <input type="hidden" name="external_attribution_id" value="${clickId}"/>
            <input type="hidden" name="external_attribution_source" value="impact"/>`;

      $('.js-sign-up-form').append(attributionInputs);
      $('.js-facebook-register').append(attributionInputs);
    }
  }, 2000);

  const handleFacebookSignInError = function () {
    Alerts.showToast({
      severity: 'danger',
      message: i18n._(
        'We were unable to sign you in with your Facebook account. Please sign in using your email address instead.'
      ),
      container: '.js-facebook-alerts-container',
    });
  };

  const gdprExplicitFieldsBasedOnCountryCode = () => {
    // Allows us to skip the GDPR logic in pages without the country code config
    if ($('.js-registration-skip-gdpr').length) {
      return;
    }

    let countryCode = $(countryCodeSelector).val();
    if (!countryCode) {
      captureError(
        new Error(
          "Country code doesn't exist on the page or it's empty, using Rover.context.countryCode"
        ),
        {
          countryCode: Rover.context.countryCode,
        }
      );
      countryCode = inject('Rover.context.countryCode', 'US');
    }

    const signupCountryConfigurationRaw = $('#sign-up-country-configuration').val();
    if (!signupCountryConfigurationRaw) {
      captureError(
        new Error("#sign-up-country-configuration doesn't exist on the page or it's empty")
      );
      return;
    }
    const signupCountryConfiguration = JSON.parse(signupCountryConfigurationRaw);
    if (!_.has(signupCountryConfiguration, countryCode)) {
      captureError(new Error("signupCountryConfiguration doesn't have the country code"), {
        countryCode,
        signupCountryConfiguration,
      });
      return;
    }
    const countryConfiguration = signupCountryConfiguration[countryCode];

    if (_.has(countryConfiguration.signupCheckboxes, 'tos')) {
      $('.js-sign-up-form .js-tos').removeClass('hidden');
      const { tos } = countryConfiguration.signupCheckboxes;
      $('.js-sign-up-form .js-tos label').html(`<p>${tos.text}</p>`);
      $('.js-sign-up-form .js-tos input').prop('checked', tos.checked);
    } else {
      $('.js-sign-up-form .js-tos').addClass('hidden');
    }

    if (_.has(countryConfiguration.signupCheckboxes, 'marketingCommsOptIn')) {
      $('.js-sign-up-form .js-opt-in').removeClass('hidden');
      const { marketingCommsOptIn } = countryConfiguration.signupCheckboxes;
      $('.js-sign-up-form .js-opt-in label').html(`<p>${marketingCommsOptIn.text}</p>`);
      $('.js-sign-up-form .js-opt-in input').prop('checked', marketingCommsOptIn.checked);
    } else {
      $('.js-sign-up-form .js-opt-in').addClass('hidden');
    }

    const { signUpConfirmText } = countryConfiguration;
    $('.js-sign-up-form .js-sign-up-confirm .js-sign-up-confirm-text').html(
      `<p>${signUpConfirmText}</p>`
    );
  };

  const selectCountryCode = function (e) {
    const values = e.target.value.split(',');
    const countryCode = values[0];
    const countryName = values[1];
    $(countryCodeSelector).val(countryCode);
    $('.sign-up-country-value').text(`${countryName}?`);

    if ($('#zip_code').val()) $('#zip_code').val('');
    gdprExplicitFieldsBasedOnCountryCode();
    // This will change the selected text of the dropdown back to "Switch Country" but will keep selected the language
    const $select = document.querySelector('#sign-up-country-selector');
    $select.value = i18n._('Switch Country');
  };

  const facebookSignIn = function () {
    Rover.facebook.signup(false, handleFacebookSignInError);
  };

  const handleFacebookSignUpError = function () {
    dataLayer.push({
      event: 'account-sign-up-facebook-sign-up',
      submitStatus: 'fail',
      errorMessage: i18n._(
        'We were unable to connect with Facebook. Please sign up using your email address instead.'
      ),
    });
    Alerts.showToast({
      severity: 'danger',
      message: i18n._(
        'We were unable to connect with Facebook. Please sign up using your email address instead.'
      ),
      container: '.js-facebook-alerts-container',
    });
  };

  const facebookSignUp = function () {
    Rover.facebook.signup(false, handleFacebookSignUpError);
  };

  const fireAnalyticsForToggle = function (e, data) {
    if (data.on !== '.js-sign-in' && data.on !== '.js-sign-up') return;

    const eventName = data.on.replace('.js-', '');
    switch (eventName) {
      case 'sign-up':
        dataLayer.push({
          event: 'account-sign-in-click-to-sign-up',
        });
        break;
      case 'sign-in':
        dataLayer.push({
          event: 'account-sign-up-click-to-sign-in',
        });
        break;
      default:
        break;
    }
  };

  const fireAnalyticsForSubmit = function (e) {
    if ($(e.currentTarget).hasClass('js-sign-up-form')) {
      dataLayer.push({ event: 'sign-up-button-clicked' });
    } else {
      dataLayer.push({
        event: 'account-sign-up-click-to-sign-in',
      });
    }
  };

  const fireAnalyticsForClick = function (type, action) {
    return function () {
      if (type === 'google' || type === 'apple') {
        if (action === 'sign-in' || action === 'sign-up') {
          dataLayer.push({ event: `account-${action}-${type}` });
        }
      }
    };
  };

  const initializeSignInValidation = () => {
    $('.js-sign-in-form').roverValidate();
  };
  const enableSignUpButtonAfterFormChanges = () => {
    $('.sign-up-form')
      .find('.form-control')
      .on('change', () => {
        $('.sign-up-form')
          .find(':submit')
          .not('.js-prevent-validate-disabled')
          .prop('disabled', false);
      });
  };
  const initializeSignUpValidation = () => {
    enableSignUpButtonAfterFormChanges();
    $('.js-sign-up-form').roverValidate({
      focusInvalid: false,
      // if focusInvalid is true, the form auto-scrolls to previous invalid input on blur, which is annoying.
      // This makes it focus the first invalid element only on submit (See https://stackoverflow.com/a/10112391/1094910)
      invalidHandler: (form, validator) => {
        if (validator.numberOfInvalids() > 0) validator.errorList[0].element.focus();
      },
      rules: {
        first_name: {
          required: true,
          nameValidate: { name: $('#first_name').val() },
        },
        last_name: {
          required: true,
          nameValidate: { name: $('#last_name').val() },
        },
        email: {
          required: true,
          email: true,
          remote: {
            url: Rover.urls.validateEmail,
            type: 'GET',
          },
        },
        zip_code: {
          required: true,
          zippostalValidate: {
            countrySelector: $('#country_code'),
          },
          remote: {
            url: Rover.urls.validateZipcode,
            type: 'GET',
            data: {
              remote: true,
              cache: false,
              country_code() {
                return $(countryCodeSelector).val();
              },
            },
          },
        },
        password: {
          required: true,
          remote: {
            url: Rover.urls.validatePassword,
            type: 'POST',
            data: {
              remote: true,
              cache: false,
              first_name() {
                return $('#first_name').val();
              },
              last_name() {
                return $('#last_name').val();
              },
              email() {
                return $('#signup-email').val();
              },
            },
          },
        },
        agree_to_tos: {
          required: true,
        },
      },
      messages: {
        agree_to_tos: {
          required: i18n._('Please agree to our Terms of Service and Privacy Statement.'),
        },
      },
    });
  };

  const referralSourceDetail = function () {
    const isOther = $(this).val() === 'other';
    const $other = $("[name='referral_source_detail']");

    if (isOther) {
      $other.removeClass('hide');
      $other.focus();
    } else {
      $other.addClass('hide');
    }
  };

  const initMailcheck = function () {
    const templateMessage = i18n._('Did you mean');
    const $input = $('.js-signup-email');
    const $formGroup = $input.closest('.form-group');
    const template = `<p class="help-text text-info"><i class="rover-icon rover-icon-in-exclamation-triangle"></i> ${templateMessage} {{ address }}@<strong>{{ domain }}</strong>?</p>`;

    const clearSuggestion = function () {
      $formGroup.find('.help-text').remove();
    };

    const addSuggestion = function (suggestionObj) {
      $formGroup.append(Mustache.render(template, suggestionObj));
    };

    $input.on('blur', () => {
      $input.mailcheck({
        suggested(el, suggestion) {
          clearSuggestion();
          addSuggestion(suggestion);
        },
        empty: clearSuggestion,
      });
    });
  };

  const addBindings = () => {
    $('.js-sign-up-form .agree-to-tos').on('change', () => {
      const $agreeTOS = $('.js-sign-up-form .agree-to-tos');
      const $agreeTOSValidation = $('.js-sign-up-form .agree-to-tos-validation');
      $agreeTOSValidation.prop('checked', $agreeTOS.prop('checked'));
    });
    $('.js-email-sign-up').on('click', initializeSignUpValidation);
    $('.js-facebook-sign-up').on('click', facebookSignUp);
    $('.js-facebook-sign-in').on('click', facebookSignIn);
    $('.js-google-sign-up').on('click', fireAnalyticsForClick('google', 'sign-up'));
    $('.js-google-sign-in').on('click', fireAnalyticsForClick('google', 'sign-in'));
    $('.js-apple-sign-up').on('click', fireAnalyticsForClick('apple', 'sign-up'));
    $('.js-apple-sign-in').on('click', fireAnalyticsForClick('apple', 'sign-in'));
    $('.js-sign-up-form, .js-sign-in-form').on('submit', fireAnalyticsForSubmit);
    $('[name="referral_source"]').on('change', referralSourceDetail);
    $(window).on('toggle-fired', fireAnalyticsForToggle);

    $('.sign-up-country-selector').on('change', selectCountryCode);
  };

  const setFieldsFromIntl = () => {
    if (window.Intl) {
      const resolvedOptions = Intl.DateTimeFormat().resolvedOptions();
      const localeInputs = document.getElementsByClassName('js-intl-locale');
      for (let i = 0; i < localeInputs.length; i += 1) {
        localeInputs[i].value = resolvedOptions.locale;
      }
      const timeZoneInputs = document.getElementsByClassName('js-intl-time-zone');
      for (let i = 0; i < timeZoneInputs.length; i += 1) {
        timeZoneInputs[i].value = resolvedOptions.timeZone;
      }
    }
  };

  const init = () => {
    addBindings();
    initializeSignInValidation();
    initMailcheck();
    setFieldsFromIntl();
    gdprExplicitFieldsBasedOnCountryCode();
  };

  init();
});
